/**
 * mobilemenu.js
 */
+function ($) {
    /**
    *    save whether the mobileMenu ist displayed or not
    */
    var displayMenu = false;

    $(document).ready(function ($) {
        if ($(window).width() < 768) {
            /**
            *    shows/hides the mobileMenu
            */
            $(window).on("swipeleft", function(event) {
                // $('#siteMaincontent').addClass('transform');
                $('#mobileMenu').addClass('transform');
                displayMenu = true;
            });

            $(window).on("swiperight", function(event) {
                $('#mobileMenu').removeClass('transform');
                // $('#siteMaincontent').removeClass('transform');
                displayMenu = false;
            });


            $('#siteMaincontent').bind('click', function(event) {
                if (event.target.id === "showMenuBtn" && !displayMenu) {
                    // $('#siteMaincontent').addClass('transform');
                    $('#mobileMenu').addClass('transform');
                    displayMenu = true;
                }
                else if (displayMenu) {
                    $('#mobileMenu').removeClass('transform');
                    // $('#siteMaincontent').removeClass('transform');
                    displayMenu = false;
                }
            });
        }
    });
}(jQuery);
