 /**
  * herosectionform.js
  */
 +function ($) {
    $(document).ready(function ($) {
        /**
        *  Trigger herosectionform
        */
        var HeroSectionFormHeight = $('#herosectionform').css('height');
        var HeroSectionFormHeaderHeight = $('#herosectionformheader_bg').css('height');
        var HeroSectionFormUnderlineHeight = $('#herosectionformunderline').css('height');
        var ClosedHeight = '-' + (parseInt(HeroSectionFormUnderlineHeight) + parseInt(HeroSectionFormHeaderHeight)) + 'px';
        var OpendHeight = '-' + (parseInt(HeroSectionFormUnderlineHeight) + parseInt(HeroSectionFormHeight)) + 'px';
        var Caret = $('#herosectionformtrigger i');
        var Form = $('#herosectionform');
        var Finder = $('#herosectionform').attr('data-finder');
        var Open = false;

        /**
        *    if the finder is the curent page, the herosectionform stays open to display the filter
        *    otherwise the herosectionform gets minimized
        */
        // if (Finder === 'true') {
        //     TweenMax.set(Form, {y: OpendHeight});
        //     Caret.removeClass('fa-caret-up');
        //     Caret.addClass('fa-caret-down');
        //     Open = true;
        // }
        // else {
        //     TweenMax.set(Form, {y: ClosedHeight, height: HeroSectionFormHeaderHeight});
        // }

        /**
        *    opens and closes the herosectionform
        */
        // $('#herosectionformtrigger').bind('click', function(event) {
        //     if (Open) {
        //         TweenMax.to(Form, 0.5, {y: ClosedHeight, height: HeroSectionFormHeaderHeight, overflow: "hidden", zIndex: 8, ease: Back.easeInOut});
        //         Caret.removeClass('fa-caret-down');
        //         Caret.addClass('fa-caret-up');
        //         Open = false;
        //     }
        //     else {
        //         TweenMax.to(Form, 0.5, {y: OpendHeight, height: HeroSectionFormHeight, ease: Back.easeOut, onComplete: function() {
        //             TweenMax.set(Form, {overflow: "visible", zIndex: 9})
        //         }});
        //         Caret.removeClass('fa-caret-up');
        //         Caret.addClass('fa-caret-down');
        //         Open = true;
        //     }
        // });

        // Filter - new search
        $('#NewSearch').bind('click', function() {
            $(this).addClass('hidden');
            $('#Filter').addClass('hidden');
            $('#quicksearch').removeClass('hidden');
            $("#quicksearch").css('display', 'flex');
            if ($(window).width() < 768) {
                $("#herosectionform_bg").addClass('open');
                $(".mobile_search_close_btn").show();
                $("#Filter").css('display', 'none');
                $(".mobile_filter_close_btn").hide();
            }
            // HeroSectionFormHeight = $('#herosectionform').css('height');
            // MarginTopOpend = '-' + (parseInt(HeroSectionFormUnderlineHeight) + parseInt(HeroSectionFormHeight)) + 'px';
            // $('#herosectionform').css({
            //     'margin-top': MarginTopOpend,
            //     'overflow': 'visible',
            //     'z-index': '9'
            // });
        });

        $(".mobile_search_open_btn").click(function(event) {
            $(".mobile_search_close_btn").show();
            $(this).hide();
            $("#quicksearch").css('display', 'flex');
            $("#herosectionform_bg").addClass('open');
        });

        $(".mobile_search_close_btn").click(function(event) {
            $(".mobile_search_open_btn").show();
            $(this).hide();
            $("#quicksearch").css('display', 'none');
            $("#herosectionform_bg").removeClass('open');
        });

        $(".mobile_filter_open_btn").click(function(event) {
            $(".mobile_filter_close_btn").show();
            $(this).hide();
            $("#Filter").css('display', 'flex');
            $("#herosectionform_bg").addClass('open');
        });

        $(".mobile_filter_close_btn").click(function(event) {
            $(".mobile_filter_open_btn").show();
            $(this).hide();
            $("#Filter").css('display', 'none');
            $("#herosectionform_bg").removeClass('open');
        });
    });
}(jQuery);
