/**
 * quicksearch-v2.1.js
 */
$(document).ready(function() {
	var bezirksnamen = [];
	bezirksnamen [0] = "Mitte";
	bezirksnamen [1] = "Treptow-Köpenick";
	bezirksnamen [2] = "Charlottenburg-Wilmersdorf";
	bezirksnamen [3] = "Prenzlauer Berg-Pankow";
	bezirksnamen [4] = "Lichtenberg";
	bezirksnamen [5] = "Marzahn-Hellersdorf";
	bezirksnamen [6] = "Friedrichshain-Kreuzberg";
	bezirksnamen [7] = "Tempelhof-Schöneberg";
	bezirksnamen [8] = "Neukölln";
	bezirksnamen [9] = "Steglitz-Zehlendorf";
	bezirksnamen [10] = "Spandau";
	bezirksnamen [11] = "Reinickendorf";
	bezirksnamen [12] = "Berlin";

	var geoinfoBerlin = [];
	geoinfoBerlin [0] = "lat=52.53596&lng=13.360721&zoom=13";
	geoinfoBerlin [1] = "lat=52.439897&lng=13.589348&zoom=12";
	geoinfoBerlin [2] = "lat=52.515594&lng=13.307506&zoom=14";
	geoinfoBerlin [3] = "lat=52.574681&lng=13.436569&zoom=12";
	geoinfoBerlin [4] = "lat=52.5369&lng=13.501114&zoom=12" ;
	geoinfoBerlin [5] = "lat=52.523742&lng=13.581795&zoom=13";
	geoinfoBerlin [6] = "lat=52.50943&lng=13.424091&zoom=13";
	geoinfoBerlin [7] = "lat=52.442199&lng=13.385071&zoom=12";
	geoinfoBerlin [8] = "lat=52.449105&lng=13.446525&zoom=12";
	geoinfoBerlin [9] = "lat=52.442618&lng=13.230919&zoom=12";
	geoinfoBerlin [10] = "lat=52.526039&lng=13.191093&zoom=11";
	geoinfoBerlin [11] = "lat=52.59846&lng=13.29718&zoom=12";
	geoinfoBerlin [12] = "lat=52.515594&lng=13.307506&zoom=9";


	var landkreisnamen = [];
	landkreisnamen [0] = "München";
	landkreisnamen [1] = "Pfaffenhofen";
	landkreisnamen [2] = "Fürstenfeldbruck";
	landkreisnamen [3] = "Starnberg";
	landkreisnamen [4] = "Dachau";
	landkreisnamen [5] = "München Landkreis";
	landkreisnamen [6] = "Landshut";
	landkreisnamen [7] = "Freising";
	landkreisnamen [8] = "Ebersberg";
	landkreisnamen [9] = "Miesbach";
	landkreisnamen [10] = "Erding";
	landkreisnamen [11] = "Aichbach-Friedberg";
	landkreisnamen [12] = "Bad Tölz";
	landkreisnamen [13] = "Augsburg";
	landkreisnamen [14] = "Weilheim-Schongau";
	landkreisnamen [15] = "Landsberg am Lech";
	landkreisnamen [16] = "Bayernweit";


	var geoinfoMuenchen = [];
	geoinfoMuenchen [0] = "lat=48.150788&lng=11.565868&zoom=10";
	geoinfoMuenchen [1] = "lat=48.615713&lng=11.538328&zoom=10";
	geoinfoMuenchen [2] = "lat=48.192673&lng=11.213554&zoom=11";
	geoinfoMuenchen [3] = "lat=48.014107&lng=11.333279&zoom=11";
	geoinfoMuenchen [4] = "lat=48.336488&lng=11.378147&zoom=11"; 
	geoinfoMuenchen [5] = "lat=48.016157&lng=11.617931&zoom=11";
	geoinfoMuenchen [6] = "lat=48.572726&lng=12.194402&zoom=10";
	geoinfoMuenchen [7] = "lat=548.454805&lng=11.758080&zoom=13"; 
	geoinfoMuenchen [8] = "lat=48.073582&lng=11.924964&zoom=11";
	geoinfoMuenchen [9] = "lat=47.735433&lng=11.818574&zoom=12"; 
	geoinfoMuenchen [10] = "lat=48.306372&lng=12.003856&zoom=11";
	geoinfoMuenchen [11] = "lat=48.426975&lng=11.062435&zoom=11"; 
	geoinfoMuenchen [12] = "lat=47.735810&lng=11.525740&zoom=10";
	geoinfoMuenchen [13] = "lat=48.359286&lng=10.899418&zoom=12";
	geoinfoMuenchen [14] = "lat=47.803880&lng=11.072360&zoom=12";
	geoinfoMuenchen [15] = "lat=48.032996&lng=10.867457&zoom=12";
	geoinfoMuenchen [16] = "lat=48.159972&lng=11.808430&zoom=7";

	var mapInfoTag = '<div id="mapInfo" class="hidden"></div>';
	$('body').append(mapInfoTag);

	var mapInfo = $("#mapInfo");
	// console.log(mapInfo);
	// Variablendefinition für URL
	var ort = "";
	var ortid = "";
	var aktuelleKategorie = "";
	var aktuelleUnterkategorie = "";
	var vermarktungsart = "alle";

	// setzt den aktuellen Ort
	function setLocation(id){
		ort = id;
		ortid = "#" + ort;
	}

	// Info Ausblendung bei onmouseleave
	function clearInfo() {
		mapInfo.addClass('hidden');
		mapInfo.html("");

		// clear MapInfo vom Coursor
		$(document).off('mousemove');
	}

	// Info Einblendung bei onmouseenter
	function einblenden(id) {
		mapInfo.removeClass('hidden');
		switch (ort) {
			case "Berlin":
			mapInfo.html(bezirksnamen[id]);
			break;
			case "Muenchen":
			mapInfo.html(landkreisnamen[id]);
			break;
		}
		
		// bind MapInfo an den Coursor
		$(document).on('mousemove', function(e){
			$('#mapInfo').css({
				left:  e.pageX + 10 ,
				top:   e.pageY + 15
			});
		});
	}

	// Buttonabhängikeiten auflösen
	$(ortid + " .kategorie").click(function(){
		// überprüft ob eine andere Kategorie angezeigt wird und versteckt sie ggf
		if(aktuelleKategorie !== "") {
			$(ortid + " ." + aktuelleKategorie).addClass('hidden');
			$(ortid + " .unterkategorie").removeClass('active');
			$(ortid + " .vermarktungsart").addClass('hidden');
			$(ortid + " .vermarktungsartBtn").removeClass('active');
		}
		// setzt die Kategorie
		var kategorie = $(this).attr('value');
		// zeigt die Kategorie an
		$(ortid + " ." + kategorie).removeClass('hidden');
		// setzt die aktuelle Kategorie
		aktuelleKategorie = kategorie;
		// zeigt abhängig von der Kategory "kaufen" und "mieten" an
		$(".unterkategorie").click(function(){
			aktuelleUnterkategorie = $(this).attr('value');
			switch (kategorie) {
				case "private":
				$(ortid + " .vermarktungsart").removeClass('hidden');
				$(ortid + " .miete").removeClass('hidden');
				$(ortid + " .kauf").removeClass('hidden');
				break;
				case "commercial":
				$(ortid + " .vermarktungsart").removeClass('hidden');
				$(ortid + " .miete").removeClass('hidden');
				$(ortid + " .kauf").removeClass('hidden');
				break;
				case "investment":
				$(ortid + " .vermarktungsart").removeClass('hidden');
				$(ortid + " .miete").addClass('hidden');
				$(ortid + " .kauf").removeClass('hidden');
				break;
			}
		});
		$(ortid + " .vermarktungsartBtn").click(function(){
			vermarktungsart = $(this).attr('value');
		});
	});

	// Auswerten des Forumlars und Erzeugung des Links
	function animate() {
		$(".span4 .lead").css('color', 'red');
	}

	function open(id) {
		// Link wird erstellt
		if (ort !== ""){
			ort += "/";
		}
		else {
			console.log("error");
			animate();
			return false;
		}
		if (aktuelleKategorie !== ""){
			aktuelleKategorie += "/";
		}
		else {
			console.log("error");
			animate();
			return false;
		}
		if (aktuelleUnterkategorie !== ""){
			aktuelleUnterkategorie += "/";
		}
		else {
			console.log("error");
			animate();
			return false;
		}
		if (vermarktungsart == ""){
			vermarktungsart = "alle";
		}

		// Only needed if more than one location is needed
		// var url = ("/" + aktuelleKategorie + ort + "finder/" + aktuelleUnterkategorie + vermarktungsart);
		// If only one location is needed
		var url = ("/" + aktuelleKategorie + "finder/" + aktuelleUnterkategorie + vermarktungsart);
		// Wenn das Formular über die Karte aufgerufen wurde füge die GeoInfos an den Link
		switch (ort) {
			case "Berlin/":
			if (geoinfoBerlin[id]) {
				url += ("?" + geoinfoBerlin [id]);
			}
			break;
			case "Muenchen/":
			if (geoinfoMuenchen[id]) {
				url += ("?" + geoinfoMuenchen [id]);
			}
			break;
		}
		// Link wird aufgerufen in Abbhänigkeit von der Seite auf der er sich befindet
		window.location.href = url; //window.location.href = url; //alert(url);
	}

	// Bootstrap app
	$('#quicksearchmapframe').on('mouseenter', function() {
		setLocation($(this).context.dataset.location);
	});

	$('#quicksearchmapframe').on('mouseleave', function() {
		setLocation(null);
	});

	$('.quicksearchmapelement').on('mouseenter', function(event) {
		var districtNumber = $(this).context.dataset.districtnumber;
		if (!(bezirksnamen[districtNumber] == ort)) {
			einblenden(districtNumber);
		}
	});

	$('.quicksearchmapelement').on('mouseleave', function(event) {
		clearInfo();
	});	

	$('.quicksearchmapelement').on('click', function(event) {
		var districtNumber = $(this).context.dataset.districtnumber;
		open(districtNumber);
	});
});



