/**
 * formHandler.js
 */
(($) => {
	$(document).ready(($) => {
		const messageOutput = $('#formMessage')

		$('input[type="submit"], button[type="submit"]').click(function(e) {
			e.preventDefault()
			const form = $(this).closest('form')
			const data = form.serializeArray()
			$.ajax({
				url: form.attr('action'),
				type: form.attr('method'),
				// dataType: 'default: Intelligent Guess (Other values: xml, json, script, or html)',
				data: data
			})
			.done(function(msg) {
				processResponse(msg, form)
			})
			.fail(function(msg) {
				messageOutput.html(msg)
				messageOutput.addClass('alert alert-error')
			})		
		})

		function processResponse(msg, form) {
			try {
				const response = JSON.parse(msg)
				if (typeof(response) !== 'undefined') {
					if (response.error !== false) {
						console.log(response.message)
						messageOutput.html(response.message)
						messageOutput.removeClass('alert-success')
						messageOutput.addClass('alert alert-danger')
					} else {
						form.remove()
						messageOutput.html(response.message)
						messageOutput.removeClass('alert-danger')
						messageOutput.addClass('alert alert-success')
					}
				}

			} catch (e) {
				messageOutput.html('Es ist ein Fehler aufgetreten. Bitte versuche es später noch einmal.')
				messageOutput.addClass('alert alert-danger')
				console.log(msg)
			}
		}
	});
})(jQuery);