/**
 * quicksearchformV3.js
 */
(($) => {
    $(document).ready(($) => {
        /**
        *   Quicksearch
        *   urlformat: /finder/commercial/06/miete
        *
        *   vars for post request
        */
        var category = 'private'; // default
        var subcategory;
        var marketingType = null;
        var location;

        /**
        *    #### main section ####
        */

        /**
        *   add event handler
        */
        $('#marketingType label').bind('click', chooseMarketingType);
        $('#category .dropdown_menu li').bind('click', chooseCategory);
        $('#subcategory .dropdown_menu li').bind('click', chooseSubcategory);
        $('#location').bind('input', chooseLocation);
        $('#searchObjects').bind('click', searchObjects);

        /**
        *    #### functions ####
        */

        /**
        *    choose category
        */
        function chooseCategory(event) {
            /**
            *    reset of all parameters
            */
            category = setActive(this);

            /**
            *    get the subcategories and marketingtypes to enable for this category
            */
            var subcategoriesEnabled = JSON.parse($(event.target).attr('data-target-subcategory'));
            var marketingTypesEnabled = JSON.parse($(event.target).attr('data-target-marketingType'));

            var subcategoriesEnabledSelector = '#subcategory .dropdown_menu li';
            $(subcategoriesEnabled).each(function(index, value) {
                if (index > 0) {
                    subcategoriesEnabledSelector += ",[data-value=\""+value+"\"]";
                }
                else {
                    subcategoriesEnabledSelector += "[data-value=\""+value+"\"]";
                }
            });

            var marketingTypesEnabledSelector = '#marketingType .dropdown_menu li';
            $(marketingTypesEnabled).each(function(index, value) {
                if (index > 0) {
                    marketingTypesEnabledSelector += ",[data-value=\""+value+"\"]";
                }
                else {
                    marketingTypesEnabledSelector += "[data-value=\""+value+"\"]";
                }
            });

            /**
            *   enable and show only the marketingtypes and categories which belong to this category
            *   also enable location input and submit button
            */
            $(subcategoriesEnabledSelector).removeClass('hidden');
            $(marketingTypesEnabledSelector).removeClass('hidden');
            $(subcategoriesEnabledSelector).removeAttr('disabled');
            $('#subcategory .dropdown_toggle').removeAttr('disabled');
            $('#location').removeClass('hidden');
            $('#searchObjects').removeClass('hidden');
        }

        /**
        *    choose subcategory
        */
        function chooseSubcategory(event) {
            subcategory = setActive(this);
            $('#location').removeAttr('disabled');
        }

        /**
        *    choose marketing type
        */
        function chooseMarketingType(event) {
            location = '';
            category = '';
            subcategory = '';
            $('#category .dropdown_toggle').attr('disabled', 'true');
            $('#category .dropdown_toggle').html($('#category .dropdown_toggle').attr('data-original-value'));
            reset('#subcategory .dropdown_menu li');
            $('#subcategory .dropdown_toggle').attr('disabled', 'true');
            $('#subcategory .dropdown_toggle').html($('#subcategory .dropdown_toggle').attr('data-original-value'));
            $('#location').attr('disabled', 'true');
            $('#location').attr('placeholder', 'Wo suchen sie?');
            $('#searchObjects').attr('disabled', 'true');

            // We clicked on the label
            const label = $(this);
            // Which checkbox the clicked label belongs to
            const inputId = label.attr('for');
            // The checkbox, that belongs to the clicked label
            const targetCheckBox = label.parent().find('#' + inputId);
            // The other checkbox which does not belong to the clicked label
            const otherCheckbox = label.parent().find('input:not([id='+inputId+'])');

            // Check if the label we clicked on was already enabled
            // If so, we disable it again. If not we will enable it. 
            // If both where enabled we disable it and check if the other one is still enabled and set it active then.
            const disableCheckboxAgain = targetCheckBox.attr('data-value') === marketingType || marketingType === 'alle'; 
            if (disableCheckboxAgain) {
                if (otherCheckbox.is(':checked')) {
                    marketingType = setActive(otherCheckbox);
                } else {
                    marketingType = null;
                }
            } else if (!disableCheckboxAgain && marketingType === null) {
                marketingType = setActive(targetCheckBox);
            } else {
                marketingType = 'alle';
            }

            // If marketingType is not set, the user should not be able to change something else
            if (marketingType === null) {
                $('#category .dropdown_toggle').attr('disabled', 'true');
                $('#subcategory .dropdown_toggle').attr('disabled', 'true');
            } else {
                $('#category .dropdown_toggle').removeAttr('disabled');
            }
        }

        /**
        *    choose location
        */
        function chooseLocation(event) {
            $('#searchObjects').removeAttr('disabled');
            $('#searchObjects').addClass('active');
        }

        /**
        *    open url to finder
        */
        function searchObjects(event) {
            createLocation(event);
            var url = '/finder/' + category + '/' + subcategory + '/' + marketingType + '?' + location
            // console.log(url);
            window.open(url,"_self");
        }

        /**
        *    get the location gps data
        */
        function createLocation(event) {
            location = 'lng=' + $('input[name=lng]').attr('value');
            location += '&lat=' + $('input[name=lat]').attr('value');
        }

        /**
        *    sets given node to active state
        */
        function setActive(node) {
            // $(node).parent().children('button').removeClass('active');
            // $(node).addClass('active');
            return $(node).attr('data-value');
        }

        /**
        *    resets all parameter for given node
        */
        function reset(node) {
            // $(node).removeClass('active');
            $(node).addClass('hidden');
            $(node).attr('disabled', 'true');
        }
    });
})(jQuery);
