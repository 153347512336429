/**
 * dropdown.js
 */
(($) => {
    $(document).ready(($) => {
        var dropdownToggle = $('.dropdown button.dropdown_toggle');
        var dropdownMenupoints = $('.dropdown ul.dropdown_menu li');
        var activeDropdown = "";
        var openDropdownMenu = "";
        var originalValue = {};

        dropdownToggle.bind('click', open);
        dropdownMenupoints.bind('click', activate);

        function open(event) {
            if (activeDropdown != "" && activeDropdown.find('button.dropdown_toggle').html() == $(event.target.parentNode).find('button.dropdown_toggle').html()) {
                close();
            }
            else {
                if (activeDropdown != "") {
                    close();
                }
                originalValue = $(event.target).attr('data-originalvalue');
                activeDropdown = $(event.target.parentNode);
                openDropdownMenu = activeDropdown.find('.dropdown_menu');
                openDropdownMenu.toggleClass('open');
            }
        }

        function activate(event) {
            var menupoint = $(event.target);
            var value = menupoint.html();
            var activeDropdownToggle = activeDropdown.find('button.dropdown_toggle');

            if (value != originalValue) {
                activeDropdownToggle.html(value + '<span class="caret"></span>');
                openDropdownMenu.find('li').removeClass('active');
                menupoint.toggleClass('active');
            }
            close();
        }

        function close() {
            openDropdownMenu.removeClass('open');
            activeDropdown = "";
            openDropdownMenu = "";
        }
    });
})(jQuery);
